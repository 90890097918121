














































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import EventViewer from "../EventViewer.vue";
const BtnAddAppointment = () =>
  import(
    /* webpackChunkName: "add-appointment" */ "../buttons/BtnAddAppointment.vue"
  );

import { MonthItem, monthsNames } from "@/utils/monthsNames";
import { TranslateResult } from "vue-i18n";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import timeHhMm from "@/utils/timeHhMm";

import "./style.scss";
import Appointment from "@/models/Appointment";
import moment from "moment";
// import { getAPI } from "@/api/axios-base";
import { RolLabel } from "@/utils/rolesStatus";
import { getAPI } from "@/api/axios-base";
import { AxiosResponse } from "axios";

interface Event {
  name: string;
  start: Date;
  end: Date;
  color: string;
  timed: boolean;
}

export default Vue.extend({
  name: "crm-calendar",
  components: { EventViewer, BtnAddAppointment },
  data() {
    return {
      type: "month",
      types: [
        {
          label: this.$t("labelMonth"),
          value: "month",
          icon: "mdi-calendar-month",
        },
        {
          label: this.$t("labelWeek"),
          value: "week",
          icon: "mdi-calendar-week",
        },
        {
          label: this.$t("labelDay"),
          value: "day",
          icon: "mdi-calendar-text",
        },
        {
          label: this.$t("label4Day"),
          value: "4day",
          icon: "mdi-calendar-range",
        },
      ],
      mode: "stack",
      modes: ["stack", "column"],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekdays: [
        { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
        { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
        { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
        { text: "Mon, Wed, Fri", value: [1, 3, 5] },
      ],
      date: "",
      events: [],
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey darken-1",
      ],
      year: 0,
      month: "",
      // For drag & drop
      dragEvent: null,
      dragStart: null,
      dragTime: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null,
      // Event details
      openDetails: false,
      selectedEvent: {},
      selectedElement: null,
      filterPatient: "",
      filterEmployee: "",
      RolLabel: RolLabel,
      // Event details on hover
      event: null,
      patient: null,
      loadingPatientData: false,
      appointmetsTypes: [
        {
          label: "Show all",
          value: "all",
        },
        {
          label: "Treatment",
          value: "TREATMENT",
        },
        {
          label: "Surgery",
          value: "SURGERY",
        },
        {
          label: "Evaluation",
          value: "EVALUATION",
        },
        {
          label: "Follow Up",
          value: "FOLLOWUP",
        },
      ],
      oR: "SURGERYORno1",
      appointmetsType: "all",
      appointmetsFiltered: [],
    };
  },
  computed: {
    ...mapGetters(["getpatientN"]),
    ...mapState("crmAppointmentsModule", ["loading", "appointments"]),
    ...mapState("crmMedicFormModule", ["patients"]),
    ...mapState("crmEmployeeModule", {
      loadingEmployees: "loading",
      employees: "workers",
    }),
    labelDate(): string {
      return `${this.year} ${this.month}`;
    },
    eventType(): string {
      let result = "";

      if (this.event == null) return result;

      if (
        (this as any).event.data == undefined ||
        (this as any).event.data == null
      ) {
        return "";
      }

      switch ((this as any).event.data.type) {
        case "TREATMENT":
          result = "Treatment";
          break;
        case "SURGERY":
        case "SURGERYORnone":
        case "SURGERYORno1":
        case "SURGERYORno2":
          result = "Surgery";
          break;
        case "EVALUATION":
          result = "Evaluation";
          break;
        case "FOLLOWUP":
          result = "Follow Up";
          break;
      }

      return result;
    },
    subtype(): string | false {
      if (this.event == null) return false;

      let result = "Not defined";
      const data = (this as any).event.data;

      if (data == undefined || data == null) return false;

      switch (data.type) {
        case "TREATMENT":
          if (data.partial.treatmentType !== null) {
            result = data.partial.treatmentType.name;
          }

          return result;
        case "SURGERY":
        case "SURGERYORnone":
        case "SURGERYORno1":
        case "SURGERYORno2":
          if (data.partial.surgeryType !== null) {
            result = data.partial.surgeryType.name;
          }

          return result;
        default:
          return false;
      }
    },
    total(): number {
      if (
        (this as any).event === null ||
        (this as any) === undefined ||
        this.patient === null ||
        this.patient === undefined ||
        (this as any).event.data.procedure == null ||
        (this as any).event.data.procedure == undefined
      )
        return 0;

      const procedure = (this as any).patient.proceduresEntity.find(
        (procedure: any) =>
          procedure.id == (this as any).event.data.procedure.id
      );
      if (procedure === undefined || procedure === null) return 0;

      // Tomar el total según el tipo de procedimiento
      if ((this as any).event.data.procedure.procedure_type === "Surgery") {
        if (procedure.contract == null || procedure.contract == undefined)
          return 0;
        return procedure.contract.total;
      } else if (
        (this as any).event.data.procedure.procedure_type === "Treatment"
      ) {
        return procedure.totalTreatment;
      }

      return 0;
    },
    deb(): number {
      if (
        this.event == null ||
        this.event == undefined ||
        this.patient == null ||
        this.patient == undefined ||
        (this as any).event.data.procedure == null ||
        (this as any).event.data.procedure == undefined
      )
        return 0;

      const procedure = (this as any).patient.proceduresEntity.find(
        (procedure: any) =>
          procedure.id == (this as any).event.data.procedure.id
      );
      if (procedure === undefined || procedure === null) return 0;

      const payments = procedure.payments.reduce(
        (total: number, current: any) => total + parseFloat(current.amount),
        0
      );

      return 0;
    },
  },
  watch: {
    // appointments(val: any[]) {
    //   (this as any).appointmetsFiltered = val;
    //   this.filterAppoinments(this.appointmetsType);
    // },
  },
  async mounted() {
    this.setToday();
    await this.actListPatientsFilter();
    this.actListAppointment().then(() => {
      this.appointmetsFiltered = this.appointments;
    });
  },
  methods: {
    ...mapActions("crmAppointmentsModule", [
      "actListAppointment",
      "actFilterAppointment",
    ]),
    ...mapMutations("crmAppointmentsModule", ["mutDate"]),
    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),
    ...mapActions("crmMedicFormModule", ["actListPatientsFilter"]),
    setCurrentDate({ year, month }: { year: number; month: number }): void {
      this.year = year;
      (this.month as TranslateResult) = (
        monthsNames[month - 1] as MonthItem
      ).name;
    },
    clearData() {
      this.selectedElement = null;
      this.selectedEvent = {};
    },

    setToday(): void {
      const now = new Date();

      this.setCurrentDate((this.$refs.calendar as any).getNow());
      this.date = now.toLocaleDateString();
    },
    showDay() {
      this.type = "day";
    },
    closedEvent() {
      (this.$refs.eventviewer as any).close();
      this.openDetails = false;
    },
    showEvent({ nativeEvent, event }: any): void {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.openDetails = true;
        }, 10);
      };

      if (this.openDetails) {
        this.openDetails = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    editAppointment(event: Appointment) {
      (this as any).$refs.addUpdateAppointment.setToEdit(event);
    },
    // TODO: Integrar esto al api
    filterAppoinments(type: string) {
      if (
        type == "all" &&
        (this.filterEmployee == "" ||
          this.filterEmployee == null ||
          this.filterEmployee == undefined)
      ) {
        this.actListAppointment().then(() => {
          this.appointmetsFiltered = this.appointments;
        });
      } else if (
        type == "all" &&
        (this.filterEmployee !== "" ||
          this.filterEmployee !== null ||
          this.filterEmployee !== undefined)
      ) {
        this.appointmetsFiltered = this.appointments.filter(
          (appointmet: any) => {
            return appointmet.data.doctor.uuid == this.filterEmployee;
          }
        );
      }

      if (type == "SURGERY") {
        this.appointmetsFiltered = this.appointments.filter(
          (appointmet: any) => {
            return (
              appointmet.data.type == this.oR ||
              appointmet.data.doctor.uuid == this.filterEmployee
            );
          }
        );
      } else {
        this.appointmetsFiltered = this.appointments.filter(
          (appointmet: any) => {
            return (
              appointmet.data.type == type ||
              appointmet.data.doctor.uuid == this.filterEmployee
            );
          }
        );
      }
    },
    filterAppoinmentsPatient(type: string) {
      if (
        type == "all" &&
        (this.filterPatient == "" ||
          this.filterPatient == null ||
          this.filterPatient == undefined)
      ) {
        this.actListAppointment().then(() => {
          this.appointmetsFiltered = this.appointments;
        });
      } else if (
        type == "all" &&
        (this.filterPatient !== "" ||
          this.filterPatient !== null ||
          this.filterPatient !== undefined)
      ) {
        this.appointmetsFiltered = this.appointments.filter(
          (appointmet: any) => {
            return appointmet.data.patient.uuid == this.filterPatient;
          }
        );
      }

      if (type == "SURGERY") {
        this.appointmetsFiltered = this.appointments.filter(
          (appointmet: any) => {
            return (
              appointmet.data.type == this.oR ||
              appointmet.data.patient.uuid == this.filterPatient
            );
          }
        );
      } else {
        this.appointmetsFiltered = this.appointments.filter(
          (appointmet: any) => {
            return (
              appointmet.data.type == type ||
              appointmet.data.patient.uuid == this.filterPatient
            );
          }
        );
      }
    },
    onChange({ start }: any): void {
      this.mutDate(start.date);
    },

    eventSummary(event: any) {
      // ${moment(timeHhMm(event.start), "hh:mm").format("hh:mm a")}

      return `<b>${this.getpatientN}: ${event.data.patient.fullname}</b><br/>
      <b>Doctor: ${
        event.data.doctor ? event.data.doctor.fullname : ""
      }</b> <br/>
      <b>${
        event.data.procedure ? event.data.procedure.procedure_type : ""
      }<b/>  
      <br/> ${moment(timeHhMm(event.start), "hh:mm").format(
        "hh:mm a"
      )} to ${moment(timeHhMm(event.end), "hh:mm").format("hh:mm a")}`;
    },
    eventDetails(event: any) {
      this.loadingPatientData = true;

      if (event.data) {
        this.event = event;
        getAPI(`/patient/getPatient/${event.data.patient.uuid}`).then(
          (response: AxiosResponse) => {
            this.loadingPatientData = false;
            this.patient = response.data;
          }
        );
      }
    },
  },
});
